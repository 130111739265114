/* src/assets/fonts/fonts.css */

@font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets//fonts//Apercu Pro Regular.subset.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007A, 00A9;
}

/* @font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Italic.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Medium.subset.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007A, 00A9;
}

/* @font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Medium Italic.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Medium Italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Bold.subset.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007A, 00A9;
}

/* @font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Bold Italic.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Bold Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Light.subset.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007A, 00A9;
}

/* @font-face {
  font-family: "Apercu Pro";
  src:
    url("./assets/fonts/Apercu Pro Light Italic.woff2") format("woff2"),
    url("./assets/fonts/Apercu Pro Light Italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
} */
