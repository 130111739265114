/* src/index.css */
@import "./fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

.font-apercu {
  font-family: "Apercu Pro", sans-serif;
}

/* show-heart is applied via useBodyClass hook, still only want either mobile / desktop copy to appear */
@media (max-width: 767px) {
  .show-heart h2 svg {
    display: block;
  }
}
@media (min-width: 768px) {
  .show-heart form svg {
    display: block;
  }
}

@layer components {
  .container a {
    @apply hover:text-deep-indigo;
  }

  img,
  svg,
  iframe {
    vertical-align: initial;
  }

  .panel {
    @apply w-full m-auto mb-16 first:mt-20;
  }

  .box {
    @apply flex flex-col items-center justify-center w-full relative mx-auto;
  }

  .box h2 {
    @apply z-10;
  }

  .highlight {
    @apply inline-block absolute bg-tech-green block h-promise-highlight -z-10 -translate-y-2 -ml-2 text-transparent;
  }

  .heart-overlay {
    @apply absolute bg-no-repeat h-heart-mb lg:h-heart-dt w-heart-mb lg:w-heart-dt bottom-6 select-none -right-11 md:bottom-12 sm:-right-12 md:-right-14 lg:-right-28 md:bg-cover;
  }

  .box img {
    @apply mx-auto rounded-md min-w-value-mb md:min-w-fit;
  }

  .social-icon {
    @apply md:max-h-5 w-social-icon md:w-social-icon-dt h-social-icon md:h-social-icon-dt select-none inline-block align-middle bg-no-repeat bg-contain;
  }

  .legal-link {
    @apply h-8 md:h-auto;
  }

  .waitlist {
    @apply h-12 px-10 py-2 font-bold text-lg text-white rounded-full cursor-pointer w-bff-button bg-gradient-to-r from-vibrant-purple to-deep-indigo disabled:opacity-40 disabled:cursor-not-allowed;
  }

  .form-input {
    @apply w-full px-6 py-3 text-base text-gray-900 bg-white rounded-full lg:w-bff-input focus:border-deep-indigo;
  }

  .rotate-cw,
  .rotate-ccw {
    @apply min-h-mb-img-placeholder md:min-h-img-placeholder;
  }

  .fadeOut {
    @apply opacity-0 transition-opacity duration-[2000ms];
  }
  
}
